import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { BlogType } from "../type/types";
import { apiClient } from "../services/api";

export const initialBlog: BlogType = {
  id: 0,
  title: '',
  contents: '',
};

function Blog() {
  const [blog, setBlog] = useState<BlogType>(initialBlog);

  const params = useParams();
  useEffect(() => {
    const fetchBlog = async () => {
      const fetchedBlog = await apiClient.getBlog(Number(params.id));
      setBlog(fetchedBlog);
    };
    fetchBlog();
  }, []);
  return (
    <div style={{ margin: "auto", width: "1000px" }}>
      <h1>ブログ詳細画面</h1>
      <div>
        <Link to="/">TOP</Link>
      </div>
      <div>
        <Link to="/create">記事作成画面</Link>
      </div>
      <div>
        <Link to="/blogs">記事一覧画面</Link>
      </div>
      <h2>{blog?.title}</h2>
      <h3>本文</h3>
      <p>{blog?.contents}</p>
    </div>
  );
}

export default Blog;
