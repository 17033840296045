import { axiosInstance } from "../utils/axios";
import { BlogType } from "../type/types";

export type CreateBlogType = Pick<BlogType, 'title' | 'contents'>;

export const apiClient = {
  getBlog: async (id: number): Promise<BlogType> => {
    const res = await axiosInstance.get<BlogType>(`/blogs/${id}`);
    return res.data as BlogType;
  },

  getBlogs: async (): Promise<BlogType[]> => {
    const res = await axiosInstance.get<BlogType[]>('/blogs');
    return res.data as BlogType[];
  },

  createBlog: async (blog: CreateBlogType): Promise<void> => {
    await axiosInstance.post("/blogs", blog);
  },
};