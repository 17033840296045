import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { BlogType } from "../type/types";
import { apiClient } from "../services/api";

export const initialBlog: BlogType = {
  id: 0,
  title: '',
  contents: '',
};

function Blogs() {
  const [blogs, setBlogs] = useState<BlogType[]>([]);
  useEffect(() => {
    const fetchBlogs = async () => {
      const fetchedBlog = await apiClient.getBlogs();
      setBlogs(fetchedBlog);
    };
    fetchBlogs();
  }, []);
  return (
    <div style={{ margin: "auto", width: "1000px" }}>
      <h1>ブログ一覧画面</h1>
      <div>
        <Link to="/">TOP</Link>
      </div>
      <div>
        <Link to="/create">記事作成画面</Link>
      </div>
      <div>

        <ul>
          {blogs?.map((b) => (
            <Link to={`/blogs/${b.id}`} key={b.id}>
              <li>{b.title}</li>
            </Link>
          ))}
        </ul>

      </div>
    </div>
  );
}

export default Blogs;